import React from 'react';
import { Helmet } from 'react-helmet-async';
import cx from 'classnames';
import { useIntl, FormattedMessage } from 'react-intl';

import * as styles from './Subscribe.module.css';

export default function Subscribe() {
  const intl = useIntl();

  return (
    <div className={styles.container}>
      <Helmet>
        <script
          async
          src="https://js.createsend1.com/javascript/copypastesubscribeformlogic.js"
        ></script>
      </Helmet>
      <h3>
        <FormattedMessage id="general.newsletter_form.newsletter_email" />
      </h3>

      <form
        id="subForm"
        className={cx('js-cm-form', styles.subscribeForm)}
        action="https://www.createsend.com/t/subscribeerror?description="
        method="post"
        data-id="5B5E7037DA78A748374AD499497E309E7FDCBA2607E8B94F21ABC5CEE3B5E890672D88572676C4946E408144C33AC3C1A3503B0FF160962D0ABA0021E78DEE66"
      >
        <div className={styles.field}>
          <input
            autoComplete="Email"
            aria-label="Email"
            id="fieldEmail"
            name="cm-kikiz-kikiz"
            required
            type="email"
            className="js-cm-email-input qa-input-email sc-iwsKbI iMsgpL"
            placeholder={intl.formatMessage({
              id: 'general.newsletter_form.email_placeholder',
            })}
          />
        </div>
        <button className={styles.submitButton} type="submit">
          <FormattedMessage id="general.newsletter_form.submit" />
        </button>
      </form>
    </div>
  );
}
