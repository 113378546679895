import * as React from 'react';
import { graphql } from 'gatsby';
import get from 'lodash.get';
import { GatsbySeo } from 'gatsby-plugin-next-seo';

import Layout from 'components/Layout';
import Slices from 'components/Slices';
import Subscribe from 'components/Subscribe';
import useSiteMetadata from 'hooks/useSiteMetadata';

export const query = graphql`
  query HomeQuery {
    contentfulHome(slug: { eq: "home" }) {
      items {
        ... on ContentfulGallery {
          id
          title
          url
          images {
            id
            gatsbyImageData(
              layout: CONSTRAINED
              width: 1200
              placeholder: BLURRED
            )
          }
          internal {
            type
          }
        }
        ... on ContentfulImage {
          id
          title
          url
          media {
            gatsbyImageData(
              layout: CONSTRAINED
              width: 1200
              placeholder: BLURRED
            )
          }
          internal {
            type
          }
        }
        ... on ContentfulVideo {
          id
          title
          url
          cloudinaryMedia {
            id
            public_id
          }
          internal {
            type
          }
        }
      }
    }
  }
`;

const HomePage = ({ data }) => {
  const { siteUrl } = useSiteMetadata();
  const items = get(data, 'contentfulHome.items', []);

  return (
    <Layout>
      <GatsbySeo title="Home" canonical={siteUrl} />
      <Slices items={items} />
      <Subscribe />
    </Layout>
  );
};

export default HomePage;
